import React, { FunctionComponent, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Pokemon from '../models/pokemon';
import PokemonCard from '../components/pokemon-card';
import PokemonService from '../services/pokemon-service';
import PokemonSearch from '../components/pokemon-search';
/*import POKEMONS from '../models/mock-pokemon';*/
  
const PokemonList: FunctionComponent = () => {
  const [pokemons, setPokemons] = useState<Pokemon[]>([]);
  
  useEffect(() => {
    /*setPokemons(POKEMONS);*/
    /*
    fetch('http://localhost:3001/pokemons')
    .then(response => response.json())
    .then(pokemons => {
      setPokemons(pokemons);
    });
    */
    PokemonService.getPokemons().then(pokemons => setPokemons(pokemons));
  }, []);
  
  return (
    <div>
      <h1 className="center">Pokédex</h1>
      <PokemonSearch />
      <div className="container"> 
        <div className="row"> 
        {pokemons.map(pokemon => (
          <PokemonCard key={pokemon.id} pokemon={pokemon} /*borderColor="red"*/ />
        ))}
        </div>
      </div>
      <Link to={`/pokemons/add`} className="ntn btn-floating btn-large waves-effet waves-light red z-depth-3" style={{position:'fixed', bottom:'20px', right:'20px'}}>
        <i className='material-icons'>add</i>
      </Link>
    </div> 
  );
}
  
export default PokemonList;